import { LoginDto, RegisterDto, TokenResultDto } from "@/api-client";
import { Authentication } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import store, { RootState} from "..";
import router from "@/router";

// interface TokenResponse {}

export enum SocialProviders {
  Google,
  Facebook,
  Apple,
}

export enum MutationTypes {
  SetToken = "SET_Auth_Token",
}

export type AuthState = {
  token: TokenResultDto | null;
  expiry: string;
  isLoggedIn: boolean;
};

export const state: AuthState = {
  token: null, // use getter stright out of localstore or cookies
  expiry: "",
  isLoggedIn: getTokenState() != null,
};

type Getters = {
  token(state: AuthState, rootState: RootState): TokenResultDto | null;
  expiry(state: AuthState): string;
  isLoggedIn(state: AuthState): boolean;
};

function getTokenState(_state?: AuthState): TokenResultDto | null {
  if (_state && _state!.token != null) {
    return _state!.token;
  }
  const vuex = localStorage.getItem("vuex");
  const store = JSON.parse(vuex!);
  if (store == null) {
    return null;
  }
  if (store["auth"] == null) {
    return null;
  }
  const tkn = store["auth"]["token"];
  if (tkn != null) {
    if (_state) {
      _state!.token = tkn;
    }
    return tkn;
  }
  return null;
}

export const getters: GetterTree<AuthState, RootState> & Getters = {
  token(state) {
    return getTokenState(state);
  },
  expiry() {
    return store.getters["user/expiry"];
  },
  isLoggedIn(state) {
    // if (state.isLoggedIn )
    // var test = getTokenState(state) == null ? false : true;
    // console.log(`User Logged in : ${test}`)
    return state.isLoggedIn; // state.isLoggedIn;
  },
};

type Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any): void;
};

export const mutations: MutationTree<AuthState> & Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["auth"]) {
      store["auth"] = {};
    }
    store["auth"]["token"] = token;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.token = { ...token };
    if (token) {
      state.isLoggedIn = true;
    } else {
      state.expiry = ""
      state.isLoggedIn = false;
    }
  },
};

let _refreshingToken: Promise<TokenResultDto > | null = null;

export const AuthModule: Module<AuthState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    login: async (context, params: LoginDto) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountLoginPost({
          email: params.email,
          password: params.password,
        })
          .then(async (res) => {
            let token = res.data
            context.commit(MutationTypes.SetToken, token);
            await store.dispatch("user/getUserInfo", { token: token.authenticationToken });
            await store.dispatch("user/workshopsForUser");
            
            resolve();
          })
          .catch((e) => {
            console.error("LOGIN FAILED", e);
            reject(e);
          });
      });
    },
    logout: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        context.commit(MutationTypes.SetToken, null);
        store.dispatch("user/clearProfile").then(() => {
          let path = window.location.pathname
          router.push({name: "Login"});
          resolve()
        });
      });
    },
    refresh: (context, params) => {
      return (_refreshingToken ??= new Promise<TokenResultDto>((resolve, reject) => {
        const authenticationToken = state.token?.authenticationToken;
        const refreshToken = state.token?.refreshToken;
        if(authenticationToken && refreshToken) {
          Authentication.apiAccountRefreshPost(authenticationToken, refreshToken)
            .then(async (res) => {
              if(res?.data) {
                context.commit(MutationTypes.SetToken, res.data);
                store.dispatch("user/getUserInfo", { token: res.data.authenticationToken });
                resolve(res.data);
              }
            })
            .catch((e) => {
              store.dispatch("auth/logout")
              console.error("LOGIN FAILED", e);
              reject(e);
            });
        }
      }).finally(() => (_refreshingToken = null)));
    },
    register: (context, params: RegisterDto) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.apiAccountRegisterPost({
          cellNumber: params.cellNumber,
          workshopName: params.workshopName,
          email: params.email,
          password: params.password,
          acceptedPrivicyPolicy: params.acceptedPrivicyPolicy,
          firstName: params.firstName,
          lastName: params.lastName
        })
          .then((res) => {
            console.log(res);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  namespaced: true,
};
