
import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import store from '@/store'
import Logo from "@/components/Logo/index.vue"
import { Workshops } from '@/network/api'
import { WorkshopViewModel } from '@/api-client'
import handleError from '@/utils/handleError'

@Component({
  name: 'Navbar',
  props: {
    showLogo: { default: false }
  },
  components: {
    Breadcrumb,
    Hamburger,
    Logo
  }
})
export default class extends Vue {
  userWorkshops: WorkshopViewModel[] = []

  get displayName() {
    return store.getters['user/displayName']
  }


  get sidebar() {
    return AppModule.sidebar
  }

  get device() {
    return AppModule.device.toString()
  }

  get workshopId() {
    return store.getters["user/workshopId"];
  }
      
  // get avatar() {
  //   return UserModule.avatar
  // }

  created() {
    this.workshopsForUser()
  }

  handleChange(value:any) {
    store.dispatch("user/setWorkshopId", value);

    if(this.$route.path !== '/') {
      this.$router.push('/')
    }
    else {
      this.$router.go(0);
    }
  }

  async workshopsForUser() {
    Workshops.apiWorkshopsForUserGet(1, 999999)
      .then((res) => {
        if (res.data) {
            // this.isLoading = false;
            this.userWorkshops =  res.data.data as WorkshopViewModel[]
          }
        })
        .catch((error) => {
          // this.isLoading = false;
          handleError(error)
        });
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  private async logout() {
    await store.dispatch('auth/logout')
  }
}
