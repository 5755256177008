
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AppMain'
})
export default class extends Vue {
  // uncommented as this seems to mess with local storage syncing instead of help - chrome updated?

  // mounted() {
  //   window.removeEventListener('storage', (e) => this.syncLocalStorage(e));
  //   window.addEventListener('storage', (e) => this.syncLocalStorage(e)); 
  // }

  // syncLocalStorage(e:any):any {
  //   localStorage.setItem(e.key, e.newValue)
  // }
}
