import Vue from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import UniqueId from 'vue-unique-id';

// @ts-ignore
import locale from 'element-ui/lib/locale/lang/en'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import '@/permission'
import { UserModule } from "@/store/modules/user"
import { AuthModule } from "@/store/modules/auth"
import { ConfigureAxios } from "@/network/auth-interceptor"
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

ConfigureAxios()
store.registerModule("user", UserModule)
store.registerModule("auth", AuthModule)

Vue.use(mavonEditor)
Vue.use(ElementUI, { locale })
Vue.use(UniqueId)
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})
Vue.use(TawkMessengerVue, {
  propertyId : process.env.VUE_APP_TAWK_TO_PROPERTY_ID,
  widgetId : process.env.VUE_APP_TAWK_TO_WIDGET_ID
});

Vue.config.productionTip = true

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
