import { TokenResultDto } from "@/api-client";
import store from "@/store";
import axios, { AxiosStatic } from "axios";

// login call will use this
// import NodeRSA from 'node-rsa';
// const key = new NodeRSA(process.env.VUE_APP_PUBLIC_KEY!, 'pkcs1-public', {encryptionScheme:"pkcs1"});
// var encryptedPassword = key.encrypt(password, 'base64');

function isValid(date:any) {
  return date && new Date().getTime() < new Date(date).getTime()
}

export function ConfigureAxios() {
  let originalRequestRetry = false;
  axios.interceptors.request.use(
    (req) => {
      const url = new URL(req.url!);

      if (url.pathname.includes("/Account")) return req;

      let tkn = store.getters["auth/token"] as TokenResultDto | null;
      let expiration = store.getters["user/expiry"];

      if(tkn !== null){
        if(!tkn.refreshToken) {
          store.dispatch("auth/logout") //clear login data (token, profile etc) without actually logging out
          tkn = null
          return req
        }
        if(!isValid(expiration)) {
          return store.dispatch("auth/refresh").then((res) => {
            tkn = store.getters["auth/token"];
            if(tkn) {
              req.headers!.Authorization = `Bearer ${tkn.authenticationToken}`;
            }
            return req;
          });
        }
        else if(isValid(expiration) && tkn.authenticationToken){
          req.headers!.Authorization = `Bearer ${tkn.authenticationToken}`;
        }
      }
      return req;
    },
    async (error) => {
      console.log("ded");
      return Promise.reject(error);
    }
  );
  //TODO https://dev.to/franciscomendes10866/how-to-use-axios-interceptors-b7d



  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const status = error?.response ? error.response.status : null;
      if ([401].includes(status) && !originalRequestRetry) {
        originalRequestRetry = true;
        store.dispatch("auth/refresh").then((tkn) => {
          console.log("Here " + status);
          return axios.request(error.config);
        });
      } else {
        console.log("here");
        return Promise.reject(error);
      }
    }
  );
}
