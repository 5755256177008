import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import { AuthModule } from "@/store/modules/auth";
import { ElMessageOptions } from 'element-ui/types/message'
import store from './store'

NProgress.configure({ showSpinner: false })

const whiteList = ['Login', 'Register', 'EmailVerification', 'Error', 'ForgotPassword']

router.beforeEach(async(to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()
  let token = store.getters['auth/token']
  let role = store.getters['user/role']
  let path = to.path

  // Determine whether the user has logged in
  if (token?.authenticationToken) {
    if (path === '/login') {
      // If is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // Check whether the user has obtained his permission roles
      if (!role) {
        try {
          // Get user info, including roles
          await store.dispatch('user/getUserInfo', {token: token.authenticationToken})
          // Set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (err) {
          // Remove token and redirect to login page
          await store.dispatch('auth/refresh')
          // await store.dispatch('user/getUserInfo', {token: token.authenticationToken})
          Message.error(err as ElMessageOptions || 'Has Error')
          if (path !== '/login') {
            next(`/login?redirect=${path}`)
          }
          else {
            next(`/login`)
          }
          NProgress.done()
        }
      } else {
        next()
      }
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.name as string) !== -1) {
      // In the free login whitelist, go directly
      next()
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      if (path !== '/login') {
        next(`/login?redirect=${path}`)
      }
      else {
        next(`/login`)
      }
      NProgress.done()
    }
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  // set page title
  document.title = to?.meta?.title
})
