
import path from "path";
import { Component, Prop, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { isExternal } from "@/utils/validate";
import SidebarItemLink from "./SidebarItemLink.vue";
import { hasClaim } from "@/utils/applicationClaims";
import store from "@/store";
import { type } from "os";

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: "SidebarItem",
  components: {
    SidebarItemLink,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;
  @Prop({ default: "" }) private basePath!: string;

  usersPermissions: Array<string> = [];
  navItems: any[] = []

  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item, path: "" };
  }

  get showingChildNumber() {
    if (this.item.children) {
      const showingChildren = this.item.children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }

  get roles() {
    return store.getters["user/role"] as any;
  }

  get CurrentMenuType() {
    return (this.$router as any).currentRoute.meta.menuType;
  }

  get workshopId() {
    return store.getters["user/workshopId"];
  }

  async created() {
    this.usersPermissions = (this.getUserPermissions() as Array<string>) || [];
    await this.getNavItems(this.item)

    this.$watch('workshopId', async (x, y) => {
      // Get nav items again if landing on a detail page but workshop changed
      if(x != y) {
        await this.getNavItems(this.item)
      }
    })
  }

  async canView(child: any) {
    if(child) {
      const hasMeta = Object.prototype.hasOwnProperty.call(child, "meta")
      const hasClaimMeta = hasMeta ? Object.prototype.hasOwnProperty.call(child.meta, "claim") : false

      if (child && (hasMeta && (child.meta.hidden != true)) && hasClaimMeta) {
        let hasClaimAccess = false;

        if(Array.isArray(child.meta.claim) ) {
          for(let i = 0; i < child.meta.claim.length ;i++) {
            hasClaimAccess = await hasClaim(child.meta.claim[i].claimType, child.meta.claim[i].isWorkshopScoped);

            if(hasClaimAccess) {
              break;
            }
          }
        } else {
          hasClaimAccess = await hasClaim(child.meta.claim.claimType, child.meta.claim.isWorkshopScoped);
        }
        
        return hasClaimAccess ? child : false;
      } else if (child && !hasMeta || (hasMeta && child.meta.hidden != true)) {
        return child;
      }
    }

    return false;
  }

  async getNavItems(items:any) {
    let itemsToShow: any[] = []
    if(items.children?.length) {
      itemsToShow = await Promise.all(items.children.map(async (child:any) => await this.canView(child)))
    }
    itemsToShow = itemsToShow.filter((item) => {
      return item !== false;
    });
    this.navItems = itemsToShow;
  }

  getUserPermissions() {
    if (this.roles && this.roles.length) {
      const allRoutes = (this.$router as any).matcher.getRoutes();
      const parentRoutes = [] as Array<string>;

      // Get names of all parent routes
      allRoutes.forEach((route: any) => {
        // if (route.parent) {
        if (route.parent) {
          parentRoutes.push(route.parent.meta?.title || route.parent.name);
        }

        if (route.meta?.title) {
          parentRoutes.push(route.meta.title as string);
        } else if (route.name) {
          parentRoutes.push(route.name as string);
        }
      });

      // parentRoutes = parentRoutes.filter(
      //   (item, pos) => parentRoutes.indexOf(item) === pos
      // );

      // Admin - everything
      // Has access to everything, no further logic necessary
      if (this.roles.includes("Admin")) {
        return parentRoutes;
      } else {
        const permissions: any = {
          sales: ["Orders", "LandingPage", "Enquiries", "TaskOverview"],
          operations: ["Orders", "Factories", "Enquiries", "LandingPage", "TaskOverview"],
          "marketing & design": parentRoutes.filter(function (val) {
            return ["Orders", "Enquiries", "LandingPage", "TaskOverview", "Factories", "Users"].indexOf(val) === -1 ? true : false;
          }),
        };

        // Get all permissions of current user based on role/s
        let permissionArray: any = [];
        if (typeof this.roles !== "string" && this.roles.length) {
          this.roles.forEach((role: string) => {
            permissionArray = permissionArray.concat(permissions[role.toLowerCase()]);
          });
        } else if (typeof this.roles === "string") {
          permissionArray = permissions[this.roles.toLowerCase()];
        }

        // remove duplicates
        permissionArray = permissionArray.filter((item: any, pos: any) => permissionArray.indexOf(item) === pos);

        return permissionArray;
      }
    }
  }

  showOnFull(item: any) {
    const title = item?.meta?.title;

    if (!this.isCollapse) {
      if (["LandingPage", "Task Overview"].includes(title)) {
        return false;
      }
    } else {
      return this.showByRole(title);
    }
    return true;
  }

  showByRole(item: any) {
    if (this.roles.includes("Admin")) {
      return true;
    }

    if (this.usersPermissions.length) {
      let title: any;

      if (this.roles && this.roles.length) {
        typeof item === "string" ? (title = item) : (title = item?.meta?.title);

        // Return true if current user has access to this item
        return title && this.usersPermissions.includes(title) ? true : false;
      }
    }
    return false;
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath;
    }
    if (isExternal(this.basePath)) {
      return this.basePath;
    }
    return path.resolve(this.basePath, routePath);
  }
}
