// import { Profile } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import store, { RootState } from "..";
import { login, logout, getUserInfo } from '@/api/users'
// import { AdminUsers } from "@/network/api";
import router from '@/router'
import { getToken, removeToken } from '@/utils/cookies'
import { User, Workshops } from "@/network/api";
import { ApplicationClaimType, UserViewModel } from "@/api-client";
import { handleClaims } from "@/utils/applicationClaims";

interface Profile {
  name: string;
}

export type UserState = {
  token: string
  name: string
  profile: string | null;
  userId: string;
  role: Array<string>;
  expiry: number | null;
  displayName: string;
  claims: Map<ApplicationClaimType, string | string[]> | null;
  workshopId: string | null;
};

export enum MutationTypes {
  SetUserProfile = "SET_UserProfile",
  SetUserId = "SET_UserId",
  SetRole = "SET_Role",
  SetExpiry = "SET_Expiry",
  SetDisplayName = "SET_DisplayName",
  SetToken = "SET_Token",
  SetClaims = "SET_Claims",
  SetWorkshopId = "SET_WorkshopId",
}

export const state: UserState = {
  token: getToken() || '',
  name: '',
  profile: null,
  role: [],
  expiry: null,
  userId: '',
  displayName: '',
  claims: null,
  workshopId: null,
};

type Getters = {
  profile(state: UserState): string | null;
  userId(state: UserState): string;
  role(state: UserState): Array<string>;
  expiry(state: UserState): string;
  displayName(state: UserState): string;
  claims(state: UserState): Map<ApplicationClaimType, string | string[]> | null;
  workshopId(state: UserState): string | null;
};

export const getters: GetterTree<UserState, RootState> & Getters = {
  profile(state) {
    if (state.profile != null) {
      return state.profile;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const profile = store["user"]["profile"];
    if (profile != null) {
      state.profile = profile;
      return profile;
    }

    return null;
  },
  userId(state) {
    if (state.userId != '') {
      return state.userId;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const userId = store["user"]["userId"];
    if (userId != '') {
      state.userId = userId;
      return userId;
    }

    return '';
  },
  role(state) {
    if (state.role && state.role.length) {
      return state.role;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const role = store["user"]["role"];
    if (role && role.length) {
      state.role = role;
      return role;
    }

    return [];
  },
  expiry(state) {
    if (state.expiry) {
      return state.expiry;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const expiry = store["user"]["expiry"];
    if (expiry) {
      state.expiry = expiry;
      return expiry;
    }

    return '';
  },
  displayName(state) {
    if (state.displayName != '') {
      return state.displayName;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const displayName = store["user"]["displayName"];
    if (displayName != '') {
      state.displayName = displayName;
      return displayName;
    }

    return '';
  },
  claims(state) {
    if (state.claims) {
      return state.claims;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const claims = store["user"]["claims"];
    if (claims) {
      // Map did not work in local storage, had to convert to Array and then back to a Map
      const claimsMap: Map<ApplicationClaimType, string | string[]> = new Map(
        claims.map((obj:any) => {
          return [obj[0], obj[1]];
        }),
      );

      state.claims = claimsMap;
      return claimsMap;
    }

    return null;
  },
  workshopId(state) {
    if (state.workshopId != null) {
      return state.workshopId;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const workshopId = store["user"]["workshopId"];
    if (workshopId != null) {
      state.workshopId = workshopId;
      return workshopId;
    }

    return null;
  },
};

type Mutations = {
  [MutationTypes.SetUserProfile](state: UserState, userProfile: any): void;
  [MutationTypes.SetUserId](state: UserState, userId: any): void;
  [MutationTypes.SetRole](state: UserState, role: any): void;
  [MutationTypes.SetExpiry](state: UserState, expiry: any): void;
  [MutationTypes.SetDisplayName](state: UserState, role: any): void;
  [MutationTypes.SetToken](token: any): void;
  [MutationTypes.SetClaims](state: UserState, claims: any): void;
  [MutationTypes.SetWorkshopId](state: UserState, workshopId: any): void;
};

export const mutations: MutationTree<UserState> & Mutations = {
  [MutationTypes.SetUserProfile](state, profile) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["profile"] = profile;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.profile = profile;
  },
  [MutationTypes.SetUserId](state, userId) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["userId"] = userId;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.userId = userId;
  },
  [MutationTypes.SetRole](state, role) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["role"] = role;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.role = role;
  },
  [MutationTypes.SetExpiry](state, expiry) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["expiry"] = expiry;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.expiry = expiry;
  },
  [MutationTypes.SetDisplayName](state, displayName) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["displayName"] = displayName;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.displayName = displayName;
  },
  [MutationTypes.SetToken](token) {
    state.token = token
  },
  [MutationTypes.SetClaims](state, claims) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["claims"] = claims ? [...claims] : claims;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.claims = claims;
  },
  [MutationTypes.SetWorkshopId](state, workshopId) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["workshopId"] = workshopId;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.workshopId = workshopId;
  },
};

async function getDisplayName(context:any, id:string) {
  return new Promise<UserViewModel>((resolve, reject) => {
    if(id) {
      User.apiUsersIdGet(id)
      .then(async (res) => {
        if(res.data) {
          context.commit(MutationTypes.SetDisplayName, res.data.displayName);
          resolve(res.data);
        }
      })
      .catch((e) => {
        console.error("Error fetching profile", e);
        reject();
      });
    }
  });
}

export const UserModule: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    getUserInfo: async (context, params) => {
      // let token = store.getters["auth/token"]
      const data = await getUserInfo( params.token )

      if (!data) {
        throw Error('Verification failed, please Login again.')
      }

      let id = data['sub']
      let role = data['role']
      // let name = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
      let expiry = data['exp']
      let claims = await handleClaims(data)

      context.commit(MutationTypes.SetUserId, id);
      context.commit(MutationTypes.SetRole, role);
      // context.commit(MutationTypes.SetDisplayName, name);
      context.commit(MutationTypes.SetExpiry, expiry * 1000);
      context.commit(MutationTypes.SetClaims, claims);
      // getDisplayName(context, id)
    },
    getUserProfile: async (context, params) => {
      return new Promise<UserViewModel>((resolve, reject) => {
        const id = store.getters["user/userId"]
        if(id) {
          User.apiUsersIdGet(id)
          .then(async (res) => {
            if(res.data) {
              resolve(res.data);
            }
          })
          .catch((e) => {
            console.error("Error fetching profile", e);
            reject();
          });
        }
      });
    },
    workshopsForUser: async (context, params) => {
      await Workshops.apiWorkshopsForUserGet(1, 999999)
      .then((res) => {
        if (res.data) {
          // this.isLoading = false;
          if(res.data.data?.length) {
            context.commit(MutationTypes.SetWorkshopId, res.data.data[0].id);
          }
        }
      })
      .catch((error) => {
        console.log(error)
      });
    },
    setWorkshopId: (context, params) => {
      context.commit(MutationTypes.SetWorkshopId, params);
    },
    resetToken() {
      removeToken()
      mutations.SET_DisplayName
      this.commit(MutationTypes.SetToken,'')
      this.commit(MutationTypes.SetRole,[])
      this.commit(MutationTypes.SetClaims, null);
      this.commit(MutationTypes.SetExpiry, '');
      this.commit(MutationTypes.SetWorkshopId, null);
      localStorage.clear();
    },
    clearProfile: (context, params) => {
      context.commit(MutationTypes.SetUserProfile, null);
      context.commit(MutationTypes.SetUserId, '');
      context.commit(MutationTypes.SetRole, []);
      context.commit(MutationTypes.SetExpiry, '');
      context.commit(MutationTypes.SetDisplayName, '');
      context.commit(MutationTypes.SetClaims, null);
      context.commit(MutationTypes.SetWorkshopId, null);
      localStorage.clear();
    },
    async LogOut() {
      if (state.token === '') {
        throw Error('LogOut: token is undefined!')
      }
      await logout()
      removeToken()
      this.commit(MutationTypes.SetToken,'')
      this.commit(MutationTypes.SetRole,[])
      this.commit(MutationTypes.SetExpiry, '');
      this.commit(MutationTypes.SetClaims, null);
      this.commit(MutationTypes.SetWorkshopId, null);
    }
  },
  namespaced: true,
};
