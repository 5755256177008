import Vue from "vue";
import Router, { RouteConfig } from "vue-router";
import Layout from "@/layout/index.vue";
import LayoutSidebar from "@/layout/layoutSidebar.vue";
import EmptyLayout from "@/layout/emptyPage.vue";
import store from "./store";

Vue.use(Router);

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

function handleId(to:any, from:any, next:any) {
  if (to.params.id === ":id") {
    const workshopId = store.getters["user/workshopId"];
    next({ name: 'WorkshopDetailWorkshopUser', params: { id: workshopId } })
  }
  else next()
}

export default new Router({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.path != from.path) {
        return { x: 0, y: 0 };
      }
      
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
      meta: {
        title: "Login",
        hidden: true,
      },
    },
    {
      path: "/register",
      name: "Register",
      component: () => import(/* webpackChunkName: "register" */ "@/views/auth/Register.vue"),
      meta: {
        title: "Register",
        hidden: true,
      },
    },
    {
      path: "/profile",
      component: LayoutSidebar,
      meta: {
        title: "My Profile",
      },
      children: [
        {
          path: "",
          name: "Profile",
          component: () => import(/* webpackChunkName: "profile" */ "@/views/auth/UserProfile.vue"),
          meta: {
            icon: "user",
            title: "My Profile",
          },
        }
      ]
    },
    {
      path: "/confirm-email",
      name: "EmailVerification",
      component: () => import(/* webpackChunkName: "emailverification" */ "@/views/auth/EmailVerification.vue"),
      props: true,
      meta: {
        title: "Email Verification",
        hidden: true,
      }
    },
    {
      path: "/reset-password",
      name: "ForgotPassword",
      component: () => import(/* webpackChunkName: "passwordreset" */ "@/views/auth/ForgotPassword.vue"),
      props: true,
      meta: {
        title: "Password Reset",
        hidden: true,
      }
    },
    {
      path: "/404",
      name: "Error",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
      meta: {
        title: "Page Not Found",
        hidden: true,
      },
    },
    {
      path: "/",
      component: Layout,
      redirect: "/",
      name: "LandingPage",
      meta: {
        title: "GUD Portal",
      },
      children: [
        {
          path: "/",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/landing-page/index.vue"),
          meta: {
            title: "GUD Portal",
            icon: "site",
            breadcrumb: false,
            menuType: "UserCRUD|WorkshopCRUD|RewardsModule",
          },
        },
      ],
    },
    {
      path: "/workshop-management",
      component: LayoutSidebar,
      meta: {
        title: "Workshops",
        menuType: "WorkshopCRUD",
        claim: [
          {claimType: "ManageWorkshops", isWorkshopScoped: false},
          {claimType: "ViewWorkshops", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "WorkshopManagement",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/workshop/List.vue"),
          meta: {
            title: "Workshop Management",
            icon: "officebuilding",
            menuType: "WorkshopCRUD",
            claim: [
              // {claimType: "ManageWorkshops", isWorkshopScoped: false},
              {claimType: "ViewWorkshops", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "workshop/:id",
          name: "WorkshopDetail",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/workshop/Detail.vue"),
          props: true,
          meta: {
            title: "Workshop Detail",
            menuType: "WorkshopCRUD",
            hidden: true,
            claim: [
              {claimType: "ManageWorkshops", isWorkshopScoped: true},
              {claimType: "ViewWorkshops", isWorkshopScoped: true}
            ]
          },
          // beforeEnter: claimCheck
        },
        {
          path: "workshop-detail/:id?",
          name: "WorkshopDetailWorkshopUser",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/workshop/Detail.vue"),
          props: true,
          meta: {
            title: "Workshop Detail",
            menuType: "WorkshopCRUD",
            icon: "officebuilding",
            claim: [
              {claimType: "ManageWorkshops", isWorkshopScoped: true},
              {claimType: "ViewWorkshops", isWorkshopScoped: true}
            ]
          },
          beforeEnter: handleId
        },
        {
          path: "workshop",
          name: "NewWorkshop",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/workshop/Detail.vue"),
          meta: {
            title: "New Workshop",
            icon: "plus",
            menuType: "WorkshopCRUD",
            claim: [
              {claimType: "ManageWorkshops", isWorkshopScoped: false},
            ]
          },
        },
        {
          path: "workshop-users",
          name: "WorkshopUsers",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/workshop-users/List.vue"),
          meta: {
            title: "Workshop Users",
            icon: "user",
            menuType: "WorkshopUser",
            claim: [
              // {claimType: "ManageUsers", isWorkshopScoped: true}, // Not sure if this should be here...
              {claimType: "ViewUsers", isWorkshopScoped: true}
            ]
          },
        },
        {
          path: "workshop-user/:id",
          name: "WorkshopUserDetail",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/workshop-users/Detail.vue"),
          props: true,
          meta: {
            title: "Workshop User Detail",
            menuType: "WorkshopUser",
            hidden: true,
            claim: [
              {claimType: "ManageWorkshops", isWorkshopScoped: true},
              {claimType: "ViewWorkshops", isWorkshopScoped: true}
            ]
          },
        },
      ],
    },
    {
      path: "/user-management",
      component: LayoutSidebar,
      meta: {
        title: "Users",
        menuType: "UserCRUD",
        claim: [
          {claimType: "ManageUsers", isWorkshopScoped: false},
          {claimType: "ViewUsers", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "UserManagement",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/user/List.vue"),
          meta: {
            title: "User Management",
            icon: "user",
            menuType: "UserCRUD",
            claim: [
              // {claimType: "ManageUsers", isWorkshopScoped: false},
              {claimType: "ViewUsers", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "user/:id",
          name: "UserDetail",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/user/Detail.vue"),
          props: true,
          meta: {
            title: "User Detail",
            icon: "user",
            menuType: "UserCRUD",
            hidden: true,
            claim: [
              {claimType: "ManageUsers", isWorkshopScoped: false},
              {claimType: "ViewUsers", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "user",
          name: "NewUser",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/user/Detail.vue"),
          meta: {
            title: "New User",
            icon: "plus",
            menuType: "UserCRUD",
            claim: [
              {claimType: "ManageUsers", isWorkshopScoped: false},
            ]
          },
        },
      ],
    },
    {
      path: "/role-management",
      component: LayoutSidebar,
      meta: {
        title: "Roles",
        menuType: "UserCRUD",
        claim: [
          {claimType: "ManageUsers", isWorkshopScoped: false},
          {claimType: "ViewUsers", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "RoleManagement",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/roles/List.vue"),
          meta: {
            title: "Role Management",
            icon: "form",
            menuType: "UserCRUD",
            claim: [
              // {claimType: "ManageUsers", isWorkshopScoped: false},
              {claimType: "ViewUsers", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "role/:id",
          name: "RoleDetail",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/roles/Detail.vue"),
          props: true,
          meta: {
            title: "Role Details",
            icon: "user",
            menuType: "UserCRUD",
            hidden: true,
            claim: [
              {claimType: "ManageUsers", isWorkshopScoped: false},
              {claimType: "ViewUsers", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "role",
          name: "NewRole",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/roles/Detail.vue"),
          meta: {
            title: "New Role",
            icon: "plus",
            menuType: "UserCRUD",
            claim: [
              {claimType: "ManageUsers", isWorkshopScoped: false},
            ]
          },
        },
      ]
    },
    {
      path: "/rewards",
      component: LayoutSidebar,
      meta: {
        title: "My Rewards",
        menuType: "RewardsModule",
        claim: [
          {claimType: "ManageClaims", isWorkshopScoped: true},
          {claimType: "ViewClaims", isWorkshopScoped: true}
        ]
      },
      children: [
        {
          path: "",
          name: "RewardsModule",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/claim/Dashboard.vue"),
          meta: {
            title: "Dashboard",
            menuType: "RewardsModule",
            icon: "dashboard",
            breadcrumb: false,
            claim: [
              // {claimType: "ManageClaims", isWorkshopScoped: true},
              {claimType: "ViewClaims", isWorkshopScoped: true}
            ]
          },
        },
        {
          path: "upload-claim",
          name: "ClaimUpload",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/claim/Submit.vue"),
          meta: {
            title: "Upload Invoice",
            icon: "documentadd",
            menuType: "RewardsModule",
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: true},
              // {claimType: "ViewClaims", isWorkshopScoped: true}
            ]
          },
        },
        {
          path: "upload-claim/:id",
          name: "ClaimReupload",
          props: true,
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/claim/Resubmit.vue"),
          meta: {
            title: "Submit Invoice",
            icon: "documentadd",
            menuType: "RewardsModule",
            hidden: true,
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: true},
            ]
          },
        },
        {
          path: "claims",
          name: "ClaimList",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/claim/List.vue"),
          props: true,
          meta: {
            title: "Rewards History",
            icon: "messagebox",
            menuType: "RewardsModule",
            claim: [
              // {claimType: "ManageClaims", isWorkshopScoped: true},
              {claimType: "ViewClaims", isWorkshopScoped: true}
            ]
          },
        },
        {
          path: "claim/:id",
          name: "ClaimDetail",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/claim/Detail.vue"),
          props: true,
          meta: {
            title: "Claim Detail",
            menuType: "RewardsModule",
            hidden: true,
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: true},
              {claimType: "ViewClaims", isWorkshopScoped: true}
            ]
          },
        },
        {
          path: "gbucks/:id",
          name: "TransactionDetail",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/point-management/Detail.vue"),
          props: true,
          meta: {
            title: "GBucks Update Detail",
            menuType: "RewardsModule",
            hidden: true,
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: true},
              {claimType: "ViewClaims", isWorkshopScoped: true}
            ]
          },
        },
        {
          path: "report",
          name: "RewardsReport",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/report/index.vue"),
          props: true,
          meta: {
            title: "Rewards Report",
            icon: "dataline",
            menuType: "RewardsModule",
            claim: [
              // {claimType: "ManageClaims", isWorkshopScoped: true},
              {claimType: "ViewClaims", isWorkshopScoped: true}
            ]
          },
        },
      ],
    },
    {
      path: "/reward-product-management",
      component: LayoutSidebar,
      meta: {
        title: "Reward Product Management",
        menuType: "RewardsModule",
        claim: [
          {claimType: "ManageProducts", isWorkshopScoped: false},
          {claimType: "ViewProducts", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "RewardProductList",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/product/List.vue"),
          meta: {
            title: "Product List",
            icon: "files",
            menuType: "RewardsModule",
            claim: [
              // {claimType: "ManageProducts", isWorkshopScoped: false},
              {claimType: "ViewProducts", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "product/:id",
          name: "RewardProductDetail",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/product/Detail.vue"),
          props: true,
          meta: {
            title: "Product Detail",
            menuType: "RewardsModule",
            hidden: true
          },
        },
        {
          path: "product",
          name: "NewRewardProduct",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/product/Detail.vue"),
          meta: {
            title: "New Product",
            menuType: "RewardsModule",
            icon: "box",
            claim: [
              {claimType: "ManageProducts", isWorkshopScoped: false},
            ]
          },
        },
      ],
    },
    {
      path: "/gbucks-management",
      component: LayoutSidebar,
      meta: {
        title: "GBucks Management",
        menuType: "RewardsModule",
        claim: [
          {claimType: "ManageClaims", isWorkshopScoped: false},
          {claimType: "ViewClaims", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "RewardPointList",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/point-management/List.vue"),
          meta: {
            title: "Claim Request List",
            icon: "list",
            menuType: "RewardsModule",
            claim: [
              // {claimType: "ManageClaims", isWorkshopScoped: false},
              {claimType: "ViewClaims", isWorkshopScoped: false}
            ]
            // hidden: true
          },
        },
        {
          path: "review/:id",
          name: "ReviewClaimAdmin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/point-management/Review.vue"),
          props: true,
          meta: {
            title: "Claim Review",
            menuType: "RewardsModule",
            hidden: true,
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: false},
              {claimType: "ViewClaims", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "claim/:id",
          name: "ViewClaimAdmin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/claim/Detail.vue"),
          props: true,
          meta: {
            title: "Claim Detail",
            menuType: "RewardsModule",
            hidden: true,
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: false},
              {claimType: "ViewClaims", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "gbucks/:id",
          name: "ViewTransactionAdmin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/point-management/Detail.vue"),
          props: true,
          meta: {
            title: "GBucks Update Detail",
            menuType: "RewardsModule",
            hidden: true,
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: false},
              {claimType: "ViewClaims", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "claim",
          name: "NewManualClaimAdmin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/point-management/Create.vue"),
          meta: {
            title: "New Claim",
            menuType: "RewardsModule",
            icon: "documentadd",
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: false},
            ]
          },
        },
        {
          path: "manual-gbucks-update",
          name: "ManualPointUpdate",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/point-management/ManualPointUpdate.vue"),
          meta: {
            title: "Manual GBucks Update",
            menuType: "RewardsModule",
            icon: "form",
            claim: [
              {claimType: "ManageClaims", isWorkshopScoped: false},
              // {claimType: "ViewClaims", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "reports",
          name: "AdminRewardsReport",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/report/index.vue"),
          props: true,
          meta: {
            title: "Rewards Report",
            icon: "dataline",
            menuType: "RewardsModule",
            claim: [
              // {claimType: "ManageClaims", isWorkshopScoped: false},
              {claimType: "ViewClaims", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "returns",
          name: "AdminRewardsReturns",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/returns/List.vue"),
          props: true,
          meta: {
            title: "Rewards Returns",
            icon: "pricetag",
            menuType: "RewardsModule",
            claim: [
              // {claimType: "ManageReturns", isWorkshopScoped: false},
              {claimType: "ViewReturns", isWorkshopScoped: false}
            ]
          },
        },
        {
          path: "returns/:id",
          name: "ViewRewardsReturnAdmin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/returns/Detail.vue"),
          props: true,
          meta: {
            title: "Rewards Return",
            menuType: "RewardsModule",
            hidden: true,
            claim: [
              {claimType: "ManageReturns", isWorkshopScoped: false},
              {claimType: "ViewReturns", isWorkshopScoped: false}
            ]
          },
        },
      ],
    },
    {
      path: "/audit-logs",
      component: LayoutSidebar,
      meta: {
        // title: "Audit Logs",
        menuType: "AuditingModule",
        claim: {claimType: "ViewAuditLogs", isWorkshopScoped: false}
      },
      children: [
        {
          path: "",
          name: "AuditLogs",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/auditing/index.vue"),
          meta: {
            title: "Audit Logs",
            icon: "files",
            menuType: "AuditingModule",
            claim: {claimType: "ViewAuditLogs", isWorkshopScoped: false}
          },
        },
      ]
    },
    {
      path: "/promotions-bulletins-management",
      component: LayoutSidebar,
      meta: {
        // title: "Promotions and Bulletins",
        menuType: "BulletinsAdminModule",
        claim: [
          {claimType: "ViewBulletins", isWorkshopScoped: false},
          {claimType: "ManageBulletins", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "AdminPromotionsBulletins",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/promotions-bulletins/List.vue"),
          meta: {
            title: "Bulletins",
            icon: "site",
            menuType: "BulletinsAdminModule",
            claim: {claimType: "ViewBulletins", isWorkshopScoped: false}
          },
        },
        {
          path: "promotion-bulletin",
          name: "NewAdminPromotionBulletin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/promotions-bulletins/Detail.vue"),
          meta: {
            title: "New Bulletin",
            icon: "plus",
            menuType: "BulletinsAdminModule",
            claim: {claimType: "ManageBulletins", isWorkshopScoped: false}
          },
        },
        {
          path: "promotion-bulletin/:id",
          name: "ViewAdminPromotionBulletin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/management/promotions-bulletins/Detail.vue"),
          meta: {
            title: "Bulletin",
            menuType: "BulletinsAdminModule",
            hidden: true,
            claim: [
              {claimType: "ViewBulletins", isWorkshopScoped: false},
              {claimType: "ManageBulletins", isWorkshopScoped: false}
            ]
          },
          props: true
        },
      ]
    },
    {
      path: "/promotions",
      component: EmptyLayout,
      meta: {
        // title: "Promotions",
        menuType: "PromotionsModule",
        claim: [
          {claimType: "ViewBulletins", isWorkshopScoped: true},
          {claimType: "ViewBulletins", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "Promotions",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/bulletins/List.vue"),
          meta: {
            title: "Promotions",
            icon: "site",
            menuType: "PromotionsModule",
            claim: {claimType: "ViewBulletins", isWorkshopScoped: true}
          },
        },
        {
          path: "promotion/:id",
          name: "ViewPromotion",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/bulletins/Detail.vue"),
          meta: {
            title: "Promotion",
            menuType: "PromotionsModule",
            hidden: true,
            claim: [
              {claimType: "ViewBulletins", isWorkshopScoped: true},
              {claimType: "ViewBulletins", isWorkshopScoped: false},
            ]
          },
          props: true
        },
      ]
    },
    {
      path: "/bulletins",
      component: EmptyLayout,
      meta: {
        // title: "Bulletins",
        menuType: "BulletinsModule",
        claim: [
          {claimType: "ViewBulletins", isWorkshopScoped: true},
          {claimType: "ViewBulletins", isWorkshopScoped: false}
        ]
      },
      children: [
        {
          path: "",
          name: "Bulletins",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/bulletins/List.vue"),
          meta: {
            title: "Bulletins",
            icon: "site",
            menuType: "BulletinsModule",
            claim: {claimType: "ViewBulletins", isWorkshopScoped: true}
          },
        },
        {
          path: "bulletin/:id",
          name: "ViewBulletin",
          component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/bulletins/Detail.vue"),
          meta: {
            title: "Bulletin",
            menuType: "BulletinsModule",
            hidden: true,
            claim: [
              {claimType: "ViewBulletins", isWorkshopScoped: true},
              {claimType: "ViewBulletins", isWorkshopScoped: false},
            ]
          },
          props: true
        },
      ]
    },
    {
      path: "/product-finder",
      name: "ProductFinder",
      component: () => import(/* webpackChunkName: "register" */ "@/views/product-finder/ProductFinder.vue"),
      meta: {
        title: "Product Finder",
        hidden: true,
      },
    },
    // {
    //   path: "/crm",
    //   component: LayoutSidebar,
    //   meta: {
    //     title: "Customer Relationship Management",
    //     menuType: "CRMModule",
    //   },
    //   children: [        
    //     {
    //       path: "customers",
    //       name: "CustomerList",
    //       component: () => import(/* webpackChunkName: "GUD Portal" */ "@/views/rewards-module/claim/List.vue"),
    //       meta: {
    //         title: "Customer List",
    //         menuType: "RewardsModule"
    //       },
    //     },
    //   ]
    // },
    {
      path: "*",
      redirect: "/404",
      meta: { hidden: true },
    },
  ],
});
