import { render, staticRenderFns } from "./layoutSidebar.vue?vue&type=template&id=513fa023&scoped=true"
import script from "./layoutSidebar.vue?vue&type=script&lang=ts"
export * from "./layoutSidebar.vue?vue&type=script&lang=ts"
import style0 from "./layoutSidebar.vue?vue&type=style&index=0&id=513fa023&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513fa023",
  null
  
)

export default component.exports