
import Logo from "@/components/Logo/index.vue"
import { Component, Prop, Vue } from 'vue-property-decorator'
import settings from '@/settings'
import store from '@/store';

@Component({
  name: 'SidebarLogo',
  components: {
    Logo
  }
})
export default class extends Vue {
  @Prop({ required: true }) public collapse!: boolean
  debounce:any = null
}
