import axios from 'axios';
import * as GUDAPI from "@/api-client";
export const axiosInstance = axios.create();

const globalConf = new GUDAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API
});

const Authentication: GUDAPI.AccountApi = new GUDAPI.AccountApi(globalConf);
const User: GUDAPI.UsersApi = new GUDAPI.UsersApi(globalConf);
const Role: GUDAPI.RolesApi = new GUDAPI.RolesApi(globalConf);
const File: GUDAPI.FilesApi = new GUDAPI.FilesApi(globalConf);
const Product: GUDAPI.ProductsApi = new GUDAPI.ProductsApi(globalConf);
const LoyaltyPointsClaims: GUDAPI.LoyaltyPointsClaimsApi = new GUDAPI.LoyaltyPointsClaimsApi(globalConf);
const LoyaltyPointsClaimsAdmin: GUDAPI.LoyaltyPointsClaimsAdminApi = new GUDAPI.LoyaltyPointsClaimsAdminApi(globalConf);
const LoyaltyPointTransactions: GUDAPI.LoyaltyPointTransactionsApi = new GUDAPI.LoyaltyPointTransactionsApi(globalConf);
const Workshops: GUDAPI.WorkshopsApi = new GUDAPI.WorkshopsApi(globalConf);
const WorkshopUsers: GUDAPI.WorkshopUsersApi = new GUDAPI.WorkshopUsersApi(globalConf);
const LoyaltyPointsTranactionReturns: GUDAPI.LoyaltyPointsTranactionReturnsApi = new GUDAPI.LoyaltyPointsTranactionReturnsApi(globalConf);
const LoyaltyPointsTranactionReturnsAdmin: GUDAPI.LoyaltyPointsTranactionReturnsAdminApi = new GUDAPI.LoyaltyPointsTranactionReturnsAdminApi(globalConf);
const AuditLogs: GUDAPI.AuditLogsApi = new GUDAPI.AuditLogsApi(globalConf);
const BulletinsAdmin: GUDAPI.BulletinsAdminApi = new GUDAPI.BulletinsAdminApi(globalConf);
const Bulletins: GUDAPI.BulletinsApi = new GUDAPI.BulletinsApi(globalConf);
const Imports: GUDAPI.ImportsApi = new GUDAPI.ImportsApi(globalConf);
const Advance: GUDAPI.AdvanceApi = new GUDAPI.AdvanceApi(globalConf);

export { Authentication, Product, User, Role, File, LoyaltyPointsClaims, LoyaltyPointsClaimsAdmin, LoyaltyPointTransactions, Workshops, WorkshopUsers, LoyaltyPointsTranactionReturns, LoyaltyPointsTranactionReturnsAdmin, AuditLogs, BulletinsAdmin, Bulletins, Imports, Advance };