
import { compile } from 'path-to-regexp'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { hasClaim } from "@/utils/applicationClaims";
import { RouteRecord, Route } from 'vue-router'

@Component({
  name: 'Breadcrumb'
})
export default class extends Vue {
  private breadcrumbs: RouteRecord[] = [];

  @Watch('$route')
  private onRouteChange(route: Route) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith('/redirect/')) {
      return
    }
    this.getBreadcrumb()
  }

  async created() {
    await this.getBreadcrumb()
  }

  private async getBreadcrumb() {
    let matched = this.$route.matched.filter(
      item => item.meta && item.meta.title
    )
    this.breadcrumbs = await Promise.all(matched.map(async item => {
      const canViewItem = await this.canView(item) && item.meta && item.meta.title && item.meta.breadcrumb !== false
      return canViewItem ? item : undefined
    })) as any;
    this.breadcrumbs = this.breadcrumbs.filter(item => item !== undefined)
  }

  async canView(child: any) {
    if(child) {
      const hasMeta = Object.prototype.hasOwnProperty.call(child, "meta")
      const hasClaimMeta = hasMeta ? Object.prototype.hasOwnProperty.call(child.meta, "claim") : false

      if (child && (hasMeta && (child.meta.hidden != true)) && hasClaimMeta) {
        let hasClaimAccess = false;

        if(Array.isArray(child.meta.claim) ) {
          for(let i = 0; i < child.meta.claim.length ;i++) {
            hasClaimAccess = await hasClaim(child.meta.claim[i].claimType, child.meta.claim[i].isWorkshopScoped);

            if(hasClaimAccess) {
              break;
            }
          }
        } else {
          hasClaimAccess = await hasClaim(child.meta.claim.claimType, child.meta.claim.isWorkshopScoped);
        }
        
        return hasClaimAccess;
      } else if (child && !hasMeta || (hasMeta && child.meta.hidden != true)) {
        return true;
      }
    }

    return true;
  }

  private pathCompile(path: string) {
    // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    const { params } = this.$route
    const toPath = compile(path)
    return toPath(params)
  }

  // private handleLink(item: any) {
  //   const { redirect, path } = item
  //   if (redirect) {
  //     this.$router.push(redirect)
  //     return
  //   }
  //   this.$router.push(this.pathCompile(path))
  // }

  private handleLinkDirect(item: any) {
    const { redirect, path } = item
    if (redirect) {
      return redirect
    }
    return this.pathCompile(path)
  }
}
