/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'move': {
    width: 16,
    height: 16,
    viewBox: '0 0 127.06 40.94',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" _fill="#a0a0a0"><rect pid="0" width="127.06" height="11.29" rx="4"/><rect pid="1" y="29.65" width="127.06" height="11.29" rx="4"/></g></g>'
  }
})
