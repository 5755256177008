
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'Logo',
  props: {
    colour: {
        type: String,
        default: "#ffffff"
    },
    width: {
        type: String,
        default: "100%"
    },
    height: {
        type: String,
    },
    symbolOnly: {
        type: Boolean,
        default: false
    },
    isWhite: {
        type: Boolean,
        default: false
    }
  }
})
export default class extends Vue {
    viewBox = "0 0 805 190"

    @Watch('symbolOnly')
    private getViewBox() {
        if(!this.$props.symbolOnly) {
            this.viewBox = "0 0 805 190"
        }
        else {
            this.viewBox = "625 0 650 190"
        }
    }
}
