import { ProblemDetails } from "@/api-client";
import { Message as ElMessage } from 'element-ui';

export default function handleError(error: ProblemDetails | any, displayMessage = true, defaultMsg = "An unexpected error has occurred.", duration = 3000) {
  console.log(error);

  const defaultError = `<p>${defaultMsg}</p>`;
  let errorMessage = defaultError;

  if(displayMessage) {
    if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
      if(error.response?.data?.[""]) {
        errorMessage = formatErrors(error.response?.data?.[""])
      } else {
        errorMessage = `<p>${(error.response?.data as ProblemDetails)?.title || (error.response?.data as ProblemDetails)?.detail  || defaultError}</p>`;
      }

      if(error.response?.data?.errors) {
        errorMessage = formatErrors(error.response?.data?.errors)
      }
    } else if (error) {
      errorMessage = error.message || defaultError;
    }

    ElMessage.error({ message: errorMessage, showClose: true, duration: duration, dangerouslyUseHTMLString: true });
  }
}

function formatErrors(errors:ProblemDetails):string {
  const errorMessages = errors;
  let result = "";

  if(Array.isArray(errors)) {
    let arr: string[] = []
    errors.forEach((e:string) => {
      arr.push(`<p class='m-0'>${e}</p>`);
    })
    result += arr.join("")
  } else {
    if(Object.keys(errorMessages).length) {
      let arr: string[] = []
      Object.keys(errorMessages).forEach((key:any) => {
        arr.push(`<p class='m-0'>${errorMessages[key]}</p>`);
      })
      result += arr.join("")
    }
  }

  return result;
}